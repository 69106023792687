import styled from "@emotion/styled";
import { Card, Container, Divider, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import rgba from "assets/theme/functions/rgba";
import MKBox from "components/MKBox";

export const StyledMainPageContainer = styled("main")(({ theme }) => ({
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
}));

export const StyledPageSection = styled("section")(({ theme }) => ({}));
export const StyledFaqContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledWhyChooseUsCards = styled(Card)(({ theme }) => ({
  padding: "3rem 2rem",
  width: "100%",
  minHeight: "263px",
  minWidth: "300px",
  margin: "auto",
  transition: "all .2s ease-in-out",
  background: `${rgba("#fff", 1)}`,
  backdropFilter: "blur(15px)",
  borderRadius: "12px",
  borderBottom: `10px solid ${theme.palette.accent.main}`,

  "&:hover": {
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    transform: "translateY(-4px)",
    boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  },

  [theme.breakpoints.up("lg")]: {
    maxWidth: "500px",
  },
}));

export const StyledAboutKetamineCards = styled(Card)(({ theme }) => ({
  padding: ".8rem",
  minHeight: "107px",
  transition: "all .2s ease-in-out",
  background: `${rgba("#fff", 0.9)}`,
  backdropFilter: "blur(15px)",
  borderRadius: 0,
  borderLeft: `10px solid ${theme.palette.accent.main}`,
  "&:hover": {
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    transform: "translateY(-4px)",
    boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  },
}));

export const StyledResearchArticleCards = styled(Card)(({ theme }) => ({
  minWidth: "225px",
  maxWidth: "100%",
  minHeight: "300px",
  padding: "2rem",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  margin: "auto",
  [theme.breakpoints.up("md")]: {
    maxWidth: "350px",
  },
  "&:hover": {
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    transform: "translateY(-4px)",
    boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  },
}));

export const StyledFaqCards = styled(MKBox)(({ theme }) => ({
  backgroundColor: "#fff",
  "&::before": {
    display: "none",
  },
  minHeight: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

export const StyledAddOnsCards = styled(Card)(({ theme, whyprovidekap }) => ({
  borderRadius: "12px",
  width: "100%",
  minWidth: "300px",
  maxHeight: "265px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "1rem",
  padding: "1.5rem",
  borderLeft: `10px solid ${theme.palette.accent.main}`,
  "&:hover": {
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    transform: "translateY(-4px)",
    boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "49%",
    ...(whyprovidekap && {
      maxWidth: "100% !important",
      minHeight: "300px",
    }),
  },
}));

export const StyledPrecautionsCards = styled(Card)(({ theme }) => ({
  padding: "1.5rem",
  borderBottom: `10px solid ${theme.palette.accent.main}`,
  "&:hover": {
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    transform: "translateY(-4px)",
    boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  },
  [theme.breakpoints.up("lg")]: {
    borderLeft: `10px solid ${theme.palette.accent.main}`,
    borderBottom: `0px solid ${theme.palette.accent.main}`,
  },
}));
export const StyledPreparationCard = styled(Card)(({ theme }) => ({
  display: "flex",
  width: "100%",
  borderBottom: `10px solid ${theme.palette.accent.main}`,
  "&:hover": {
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    transform: "translateY(-4px)",
    boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  },
  [theme.breakpoints.up("lg")]: {
    // minHeight: "369px",

    flexDirection: "row",
    alignItems: "center",
    padding: "2rem",
    borderLeft: `10px solid ${theme.palette.accent.main}`,
    borderBottom: `0px solid ${theme.palette.accent.main}`,
  },
}));

export const StyledHowToCard = styled(Card)(({ theme }) => ({
  display: "flex",
  width: "100%",
  padding: "1.5rem",
  borderBottom: `10px solid ${theme.palette.accent.main}`,
  "&:hover": {
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    transform: "translateY(-4px)",
    boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  },
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    alignItems: "center",
    paddingInline: "2rem",
    borderLeft: `10px solid ${theme.palette.accent.main}`,
    borderBottom: `0px solid ${theme.palette.accent.main}`,
  },
}));

export const StyledMusicCard = styled(Card)(({ theme }) => ({
  display: "flex",
  width: "100%",
  padding: "1.5rem",
  gap: "2rem",
  flexDirection: "row",
  borderBottom: `10px solid ${theme.palette.accent.main}`,

  [theme.breakpoints.up("lg")]: {
    alignItems: "center",
    paddingInline: "2rem",
    borderLeft: `10px solid ${theme.palette.accent.main}`,
    borderBottom: `0px solid ${theme.palette.accent.main}`,
  },
}));

export const StyledMusicBenefitCard = styled(Card)(({ theme }) => ({
  display: "flex",
  width: "100%",
  padding: "1.5rem",
  borderBottom: `10px solid ${theme.palette.accent.main}`,
  "&:hover": {
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    transform: "translateY(-4px)",
    boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  },
  [theme.breakpoints.up("lg")]: {
    paddingInline: "2rem",
    borderLeft: `10px solid ${theme.palette.accent.main}`,
    borderBottom: `0px solid ${theme.palette.accent.main}`,
  },
}));

export const StyledProfileCards = styled(Card)(({ theme }) => ({
  // "&:nth-child(1)": {
  //   borderLeft: `10px solid ${theme.palette.primary.main}`,
  //   maxHeight: "250px",
  //   marginBlock: "auto",
  //   width: "100%",
  // },
  borderRadius: "12px",
  width: "100%",
  maxWidth: "300px",
  minHeight: "300px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  padding: "1.5rem",
  borderLeft: `10px solid ${theme.palette.accent.main}`,
  // "&:hover": {
  //   cursor: "pointer",
  //   transition: "all .3s ease-in-out",
  //   transform: "translateY(-4px)",
  //   boxShadow: `${rgba(theme.palette.accent.main, 0.6)} 0px 6px 24px`,
  // },
}));

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  justifyContent: "space-between",
}));

export const StyledButtonContainer = styled(MKBox)(({ theme }) => ({
  marginTop: "2rem",
}));

export const StyledPaginationDots = styled(MKBox)(({ theme, index, currentindex, small }) => ({
  width: small ? "14px" : "18px",
  height: small ? "14px" : "18px",
  borderRadius: "50%",
  backgroundColor: index === currentindex ? theme.palette.accent.main : theme.palette.grey[400],
  marginInline: ".5rem",
  cursor: "pointer",
}));

export const StyledTestimonialsImage = styled("img")(({ theme, top, bottom }) => ({
  height: "70px",
  width: "70px",
  position: "absolute",
  ...(top && {
    top: "50px",
    left: "5px",
  }),
  ...(bottom && {
    bottom: "50px",
    right: "5px",
  }),
}));

export const StyledSectionBackgroundWrapper = styled(MKBox)(({ theme, index, rotate }) => ({
  backgroundImage: `url(${
    "https://d3a0rs3velfrz.cloudfront.net/footer-bg-gradient.svg" === "true"
      ? "https://d3a0rs3velfrz.cloudfront.net/footer-bg-gradient.svg"
      : "https://d3a0rs3velfrz.cloudfront.net/bg-gradient.svg"
  })`,
  backgroundSize: "cover",

  ...(rotate && {
    transform: "rotate(180deg)",
  }),
}));

export const StyledDividerContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "8px",
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem",
  },
}));

export const StyledCheckImage = styled("img")(({ theme }) => ({
  height: "69px",
  width: "69px",
}));

export const StyledPageFooterContainer = styled(MKBox)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  minHeight: 200,
  display: "flex",
  alignItems: "center",
  padding: "2rem",
}));

export const StyledWhatIsKetamineMainPageContainer = styled("main")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledCreatingYourSpaceMainPageContainer = styled("main")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledImage = styled("img")(
  ({
    theme,
    large,
    whatisketamine,
    preparation,
    dayofsession,
    profilecard,
    whyprovidekap,
    findatherapist,
    findatherapistOne,
    profilebiocard,
    whattoexpect,
    insurance,
    partnerbenefits,
    technology,
    whatisneuroplasticity,
    ourservices,
    headshot,
    howto,
  }) => ({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    maxHeight: "355px",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      maxHeight: "535px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
      height: "100%",
      maxHeight: "645px",
      //! Passing 'large' prop to styled component to conditionally update height of image
      ...(large && {
        minHeight: "500px",
      }),
    },
    [theme.breakpoints.up("xxl")]: {
      width: "100%",
      height: "100%",
      maxHeight: "580px",
    },
    //! Conditionally styles for each set of images (pass sectionname={"true"} on StyledImage component) ->  if true add styles.
    ...(ourservices && {
      maxWidth: "48%",
      height: "329px !important",
      [theme.breakpoints.up("lg")]: {
        height: "295px !important",
      },
      [theme.breakpoints.up("xl")]: {
        height: "355px !important",
      },
    }),
    ...(whattoexpect && {
      maxHeight: "375px",
      maxWidth: "500px",
      boxShadow: "none",
    }),
    ...(whatisketamine && {
      minHeight: "529px !important",
      marginBottom: "2rem",
      whatisneuroplasticity,
    }),
    ...(whatisneuroplasticity && {
      height: "429px !important",
      marginBottom: "2rem",
      [theme.breakpoints.up("xl")]: {
        maxWidth: "600px",
      },
    }),
    ...(preparation && {
      minHeight: "400px",
    }),

    ...(dayofsession && {
      height: "275px !important",
      width: "100% !important",
      [theme.breakpoints.up("lg")]: {
        maxWidth: "268px !important",
      },
    }),
    ...(profilecard && {
      height: "200px !important",
      width: "200px !important",
      borderRadius: "50%",
      objectFit: "contain",
    }),
    ...(whyprovidekap && {
      height: "125px !important",
      width: "125px !important",
      margin: "0 auto",
    }),
    ...(profilebiocard && {
      height: "250px !important",
      width: "250px !important",
      backgroundPosition: "center center",
      margin: "auto",
      borderRadius: "50%",
      marginBottom: "2rem",
      objectFit: "contain",

      [theme.breakpoints.up("lg")]: {
        margin: "0 2rem 0 0",
        height: "350px !important",
        width: "350px !important",
      },
    }),
    ...(insurance && {
      height: "100%",
      width: "100%",
      // maxHeight: "420px !important",
      minHeight: "350px !important",
      marginBottom: "1rem",
    }),
    ...(partnerbenefits && {
      maxHeight: "525px !important",
    }),
    ...(technology && {
      height: "425px !important",
      marginBottom: "1rem",

      [theme.breakpoints.up("lg")]: {
        height: "400px !important",
      },
    }),
    ...(headshot && {
      height: "150px !important",
      width: "150px !important",
      borderRadius: "50%",
      objectFit: "contain",
    }),

    ...(findatherapist && {
      [theme.breakpoints.up("md")]: {
        maxHeight: "400px !important",
      },
      [theme.breakpoints.up("lg")]: {
        minHeight: "477px !important",
        maxHeight: "100% !important",
      },
    }),
    ...(findatherapistOne && {
      [theme.breakpoints.up("md")]: {
        maxHeight: "300px !important",
      },
      [theme.breakpoints.up("lg")]: {
        minHeight: "477px !important",
        maxHeight: "100% !important",
      },
    }),
    ...(howto && {
      width: "100%",
      maxWidth: "400px",
      minHeight: "300px",
      marginBottom: "2rem",
      [theme.breakpoints.up("md")]: {
        width: "100%",
        minHeight: "300px",
      },
    }),
  })
);

export const StyledDivider = styled(Divider)(({ theme }) => ({
  height: "3px",
  backgroundColor: "rgba(117, 205, 197, 1)",
  opacity: "1",
}));
export const StyledFooterLink = styled(Link)(({ theme }) => ({
  textDecoration: "underline !important",
  color: `${theme.palette.accent.main}`,
  cursor: "pointer",
  fontSize: "10px",
}));
