import React from "react";

import { StyledPageContainer } from "styles/components/PageContainer/index.styles";
import { StyledSectionContainerColorWrapper } from "styles/components/SectionContainer/index.styles";

import AboutAlmadelic from "./aboutAlmadelic";
import OurValues from "./ourValues";
import MeetOurTeam from "./meetOurTeam";
import JoinUs from "./joinUs";
import sectionJsonData from "./aboutUs.data.json"; 
import CBDivider from "components/CBDivider";
import Header from "./Header";

const AboutUs = () => {
    console.log(sectionJsonData[0]);
    if (!sectionJsonData) return null;
    return (
        <StyledPageContainer>
            <StyledSectionContainerColorWrapper>
                <Header />
            </StyledSectionContainerColorWrapper>
            
            <AboutAlmadelic data={sectionJsonData[0]} />
            <CBDivider />
            <OurValues data={sectionJsonData[1]} />

            <StyledSectionContainerColorWrapper flipBackground={true}>
                <MeetOurTeam data={sectionJsonData[2]} />
                <CBDivider />
                <JoinUs />
            </StyledSectionContainerColorWrapper>
        </StyledPageContainer>
    )
}


export default AboutUs;