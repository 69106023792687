import React from "react";
import { Link } from "react-router-dom";
// Components
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import ListComponent from "components/CBList";
// Theme
import theme from "assets/theme";

const FAQContent = ({ items }) => {
  const faqSections = Array.isArray(items.faqSections) ? items.faqSections : items;
  return (
    <section id="faq-content">
      {Array.isArray(faqSections) ? (
        faqSections.map((item, i) => (
          <MKBox key={i} sx={{ marginTop: i !== 0 && "2rem" }}>
            {item.category && (
              <MKTypography variant={"h3"} component={"h2"} mb="1rem" gutterBottom>
                {item?.category}
              </MKTypography>
            )}
            {item.FAQs.map((item, index) => {
              const cta = item?.cta;
              return (
                // Drop down accordion
                <Box
                  component={Accordion}
                  key={index}
                  padding={1}
                  marginBottom={index === item.length - 1 ? 0 : 2}
                  borderRadius={`${theme.spacing(1)} !important`}
                  sx={{
                    "&::before": {
                      display: "none",
                    },
                  }}
                >
                  {/* Question */}
                  <Box
                    component={AccordionSummary}
                    expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.secondary.main }} />}
                    aria-controls="panel1a-content"
                    id={`panel1a-header--${i}`}
                  >
                    <MKTypography variant={"h6"} component={"h3"} sx={{ fontWeight: 500 }}>
                      {item.question}
                    </MKTypography>
                  </Box>
                  {/* faq drop detail */}
                  <AccordionDetails>
                    {/* Answer */}
                    {Array.isArray(item.answer) ? (
                      item.answer.map((answer, index) => {
                        return (
                          <MKTypography
                            component={"p"}
                            variant={"body2"}
                            key={index}
                            gutterBottom
                            sx={{ marginBottom: index === item.answer.length - 1 ? "2rem" : "1rem" }}
                          >
                            {answer}
                          </MKTypography>
                        );
                      })
                    ) : (
                      <MKTypography variant={"body2"} component={"p"} sx={{ marginBottom: "2rem" }}>
                        {item.answer}
                      </MKTypography>
                    )}
                    {/* List component */}
                    {item.answerList && <ListComponent listItems={item.answerList} listType="faq" />}
                    {/* Answer Footer */}
                    {Array.isArray(item.answerFooter) ? (
                      item.answerFooter.map((content, indexsz) => {
                        return (
                          <MKTypography key={indexsz} component="p" variant={"body2"} gutterBottom>
                            {content}
                          </MKTypography>
                        );
                      })
                    ) : (
                      <MKTypography component="p" variant={"body2"}>
                        {item.answerFooter}
                      </MKTypography>
                    )}
                    {/* CTA and Links */}
                    {Array.isArray(cta)
                      ? cta.map(({ text, link }, index) => {
                          return (
                            <Link style={{ color: theme.palette.secondary.main }} to={link} key={index}>
                              <MKTypography sx={{ color: theme.palette.secondary.main }} component="p" variant={"body2"} gutterBottom>
                                {text}
                              </MKTypography>
                            </Link>
                          );
                        })
                      : cta}
                  </AccordionDetails>
                </Box>
              );
            })}
          </MKBox>
        ))
      ) : (
        <p>No FAQ data available.</p>
      )}
    </section>
  );
};

export default FAQContent;
