import React from "react";
import { Grid, Container, Stack } from "@mui/material";


import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import { ImageContainer } from "styles/components/imageContainer";
import HeaderContainer from "styles/components/headerContainer";

import MKTypography from "components/MKTypography";
import theme from "assets/theme";

const AboutAlmadelic = ({data}) => {
    if (!data) return null;


    const Sections = () => {
        const component1 = (item) => {
            return (
                <Grid item xs={12} md={12} lg={5} xl={5}>
                    <ImageContainer image={item.image} alt={item.alt} />
                </Grid>
            )
        }

        const component2 = (item) => {
            return (
                <Grid item xs={12} md={12} lg={5} xl={5} >
                    <HeaderContainer title={item.title} component="h2" variant="h2" textColor={theme.palette.primary.main}  />
                    <MKTypography component='p' variant='body1' >
                        {item.description}
                    </MKTypography>
                </Grid>
            )
        }
        return(
            data.content.map((item, index) => {
                if(index % 2 === 0){
                    return(
                        <Grid item sm={12} key={index}>
                            <TwoColumnSection key={index} leftComponent={component1(item)} rightComponent={component2(item)} direction="row" columnGap={8} rowGap={2}/>
                        </Grid>
                    )
                }
                return(
                    <Grid item sm={12} key={index}>
                        <TwoColumnSection key={index} rightComponent={component1(item)} leftComponent={component2(item)} direction="row" columnGap={8} rowGap={2}/>
                    </Grid>
                )
            })
        )
    }

    

    return(
        <StyledSectionContainer>
            <Grid container columnGap={6} rowGap={6} sx={{margin: "2rem 0rem"}}>
                <Sections />
            </Grid>
        </StyledSectionContainer>
    )
}


export default AboutAlmadelic;
