
import React from "react";
import { Grid } from "@mui/material";

// components
import HeaderContainer from "styles/components/headerContainer";
import { ImageContainer } from "styles/components/imageContainer";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";

// utils
import useMediaQueries from "utils/mediaQueries.utils";

// styles
import theme from "assets/theme";

const Header = () => {
    const {isMd} = useMediaQueries();
    return(
        <StyledSectionContainer>
            <Grid container direction="row" sx={{justifyContent: "center"}}>
                <Grid item xs={12} md={2} sx={{display: "flex"}}>
                    <ImageContainer image="https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/alma_butterfly_teal.svg" alt="Almadelic Logo" cssProps={{height: "7rem", width:"auto"}} />
                </Grid>
                <Grid item xs={12} md={3} lg={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <HeaderContainer title="About Us" component="h1" variant="h1" textColor={theme.palette.white.main} cssProps={{textAlign:isMd ? "left" : "center"}}/>
                </Grid>
            </Grid>
        </StyledSectionContainer>
    )
}

export default Header;