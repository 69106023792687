import React from "react";
import { Grid } from "@mui/material";


import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import { ImageContainer } from "styles/components/imageContainer";
import HeaderContainer from "styles/components/headerContainer";
import useMediaQueries from "utils/mediaQueries.utils";

import MKTypography from "components/MKTypography";
import theme from "assets/theme";
import { StyledGridContainer } from "./index.styles";
import FourSquareComponent from "styles/components/fourSquareComponent";
const OurValues = ({data}) => {

    const {isMd, isLg} = useMediaQueries();


    const gridSection = (item, index) => {

        return(
            <Grid item xs={12} lg={5} key={index} sx={{padding: "0rem 1rem"}}>
                <ImageContainer image={item.image} alt={item.alt} cssProps={{
                    width: "7.5rem",
                    height: "auto",
                }} />
                <HeaderContainer title={item.title} component="h2" variant="h2" textColor={theme.palette.primary.main} cssProps={{textAlign: "center"}}  />
                <MKTypography sx={{textAlign: "center"}}>
                    {item.description}
                </MKTypography>
            </Grid>
        )
    }
    
    


    return (
        <StyledSectionContainer>
            <HeaderContainer title={data.section} component="h2" variant="h1" textColor={theme.palette.primary.main} highlightText={["Values"]} highlightColor={theme.palette.accent.main} />
            <FourSquareComponent 
                component1={gridSection(data.content[0])} 
                component2={gridSection(data.content[1])} 
                component3={gridSection(data.content[2])}
                component4={gridSection(data.content[3])}
                dividerColor={theme.palette.grey[400]}
                imageURL={"https://almadelic-assets.s3.us-east-2.amazonaws.com/logos/almadelicButterFlyTransparent.webp"}    
            />
        </StyledSectionContainer>
    )
}

export default OurValues;

